import { DialogType } from "..";
import BaseDialogCtrl from "../base/controller";

export default class ReceivePresentDialogCtrl extends BaseDialogCtrl {
  _type = DialogType.ReceivePresent;

  /**
   * @type {any}
   */
  received_presents;
  on_close;

  /**
   * @param {any} received_presents 
   */
  constructor(received_presents, on_close) {
    super();

    this.received_presents = received_presents;
    this.on_close = on_close;
  }
}